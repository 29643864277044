import { all, fork } from 'redux-saga/effects';
import { createDatastoreAndRunnersSaga } from './CreateDataStoreAndRunners';
import { createRunnerSaga } from './CreateRunner';
import { stopRunnerSaga } from './StopRunner';
import { updateDataStoreAssociatedRunnersSaga } from './UpdateDatastoreAssociatedRunners';

export default function* runnerSaga() {
  yield all([
    fork(createRunnerSaga),
    fork(createDatastoreAndRunnersSaga),
    fork(updateDataStoreAssociatedRunnersSaga),
    fork(stopRunnerSaga),
  ]);
}
