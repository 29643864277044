import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { NewFileButton, NewZipFileButton } from './components';

export const UploadFileButtons = ({ storageRoot, allowZipUpload }) => {
  return (
    <Box display="flex" gap="1em">
      <NewFileButton storageRoot={storageRoot} />
      {allowZipUpload && <NewZipFileButton storageRoot={storageRoot} />}
    </Box>
  );
};

UploadFileButtons.propTypes = {
  storageRoot: PropTypes.string,
  allowZipUpload: PropTypes.bool,
};
