import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Typography,
  Stack,
  Box,
  Card,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useCurrentDataset } from '../../../../state/hooks/DatasetHooks';
import { ValidationContext } from '../../DataStore';
import { UploadFileButtons, FileOptions } from '../DataStoreItemDetails/components';
import { FileStatusIcon } from './components';
import { TableDetails } from './components/TableDetails';

export const DataStoreTablesList = ({ storageRoot, loading, tablesList }) => {
  const { t } = useTranslation();
  const dataset = useCurrentDataset();
  const { validation } = useContext(ValidationContext);
  const initialSelectedTableState = useMemo(() => ({ fileName: null, fileStoragePath: null }), []);
  const [selectedTable, setSelectedTable] = useState(initialSelectedTableState);

  useEffect(() => {
    setSelectedTable(initialSelectedTableState);
  }, [dataset, initialSelectedTableState]);

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card sx={{ height: '100%', p: 2, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1, fontWeight: 'bold' }}>Tables</Box>
            <UploadFileButtons storageRoot={storageRoot} allowZipUpload={!loading && tablesList.length === 0} />
          </Box>
          <Box sx={{ m: 1 }}></Box>
          {loading && (
            <Stack sx={{ width: 1 / 3, mx: 'auto', alignItems: 'center' }}>
              <CircularProgress />
            </Stack>
          )}
          {tablesList.length === 0 && !loading ? (
            <Typography variant="h6">
              {t('commoncomponents.datastore.details.empty.text', 'No file have been uploaded in this dataset')}
            </Typography>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
              }}
            >
              {tablesList.map((file) => {
                const fileStatus = validation.tables[file.name]?.status;
                return (
                  <Box key={file.storagePath} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <ListItemButton
                      onClick={() => setSelectedTable({ fileStoragePath: file.storagePath, fileName: file.name })}
                      dense
                      sx={{ flexGrow: 1 }}
                      selected={selectedTable.fileName === file.name}
                    >
                      <ListItem sx={{ flexGrow: 1 }}>
                        <ListItemIcon>
                          <FileStatusIcon status={fileStatus} />
                        </ListItemIcon>
                        <ListItemText primary={file.name.split('.').slice(0, -1).join('.')} />
                      </ListItem>
                    </ListItemButton>
                    <FileOptions name={file.name} storagePath={file.storagePath} datasetId={dataset.id} />
                  </Box>
                );
              })}
            </Box>
          )}
        </Card>
      </Grid>

      <Grid item xs={9} sx={{ height: '100%', overflowY: 'auto' }}>
        {selectedTable.fileName && (
          <TableDetails fileName={selectedTable.fileName} storagePath={selectedTable.fileStoragePath} />
        )}
      </Grid>
    </Grid>
  );
};

DataStoreTablesList.propTypes = {
  storageRoot: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  tablesList: PropTypes.array.isRequired,
};
