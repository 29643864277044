import { useCallback } from 'react';
import { Api } from '../../../../../../services/config/Api';
import { useUpdateDatasetInStore } from '../../../../../../state/hooks/DatasetHooks';
import { useOrganizationId } from '../../../../../../state/hooks/OrganizationHooks';
import { useWorkspaceId } from '../../../../../../state/hooks/WorkspaceHooks';

export const useDataStoreFileUpload = () => {
  const organizationId = useOrganizationId();
  const workspaceId = useWorkspaceId();
  const uploadFile = useCallback(
    async (file, filePath) =>
      await Api.Workspaces.uploadWorkspaceFile(organizationId, workspaceId, file, true, filePath),
    [organizationId, workspaceId]
  );
  const updateDatasetInStore = useUpdateDatasetInStore();
  return { uploadFile, updateDatasetInStore };
};
