import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Chip, ThemeProvider, createTheme } from '@mui/material';

const statusChipColors = {
  draft: 'default',
  unknown: 'default',
  valid: 'success',
  rejected: 'error',
  modified: 'yellowModified',
  running: 'info',
};

const customTheme = createTheme({
  palette: {
    yellowModified: {
      main: 'goldenrod',
      contrastText: '#000000',
    },
  },
});
export const StatusChip = ({ status, size, sx }) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={customTheme}>
      <Chip
        label={t(`commoncomponents.datastore.details.status.${status}`, 'Inconnu').toUpperCase()}
        color={statusChipColors[status]}
        size={size}
        sx={sx}
      />
    </ThemeProvider>
  );
};

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
  size: PropTypes.any,
  sx: PropTypes.object,
};
