import React from 'react';
import PropTypes from 'prop-types';
import { LibraryAdd, Info } from '@mui/icons-material';
import { CircularProgress, IconButton, Box } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import { useCurrentDataset } from '../../../../../../state/hooks/DatasetHooks';
import { useStartRunner } from '../../../../DataStoreHooks';

export const PushDataStoreButton = ({ running, disabled }) => {
  const startRunner = useStartRunner();
  const dataset = useCurrentDataset();
  const pushValidationStatus = dataset.associatedRunners?.push?.state;

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <FadingTooltip title="Création d'un Dataset à partir du magasin de données">
          <IconButton
            color="inherit"
            onClick={() => startRunner('push')}
            disabled={running || disabled}
            sx={{ fontSize: '45px' }}
          >
            <LibraryAdd fontSize="inherit" />
          </IconButton>
        </FadingTooltip>
        {pushValidationStatus === 'Failed' && (
          <FadingTooltip title="La création du Dataset a échoué">
            <Info sx={{ position: 'absolute', top: -2, right: -2, color: '#ec6565', cursor: 'pointer' }} />
          </FadingTooltip>
        )}
        {pushValidationStatus === 'Successful' && (
          <FadingTooltip title="La création du Dataset a réussi">
            <Info sx={{ position: 'absolute', top: -2, right: -2, color: '#40ca33', cursor: 'pointer' }} />
          </FadingTooltip>
        )}
        {running && (
          <CircularProgress
            size={51}
            sx={{
              color: 'white',
              position: 'absolute',
              top: 5,
              left: 5,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </>
  );
};

PushDataStoreButton.propTypes = {
  running: PropTypes.bool,
  disabled: PropTypes.bool,
};
