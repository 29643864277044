import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { FolderZip } from '@mui/icons-material';
import { Button } from '@mui/material';
import * as JSZip from 'jszip';
import { useCurrentDatasetId } from '../../../../../../../../state/hooks/DatasetHooks';
import { TablesBackdropOpenContext } from '../../../../../../DataStore';
import { useDataStoreFileUpload } from '../../UploadFileButtonsHooks';

export const NewZipFileButton = ({ storageRoot }) => {
  const currentDatasetId = useCurrentDatasetId();
  const { uploadFile, updateDatasetInStore } = useDataStoreFileUpload();
  const { setTablesBackdropOpen } = useContext(TablesBackdropOpenContext);
  const handleFileUpload = useCallback(
    async (event) => {
      const files = event.target.files;
      if (files.length === 0) return;
      setTablesBackdropOpen(true);
      try {
        const zip = new JSZip();
        const contents = await zip.loadAsync(files[0]);
        const uploadPromises = Object.keys(contents.files)
          .filter((filename) => !filename.endsWith('/') && filename.toLowerCase().endsWith('.csv'))
          .map(async (filename) => {
            const fileBlob = await zip.file(filename).async('blob');
            // flatening fileName to upload file and not the whole zip structure including folders
            // implies that if 2 or more files share the same name in different directories in
            //  the zip, the last uploaded one will override the others.
            const flatFileName = filename.split('/').pop();
            return uploadFile(new File([fileBlob], flatFileName), storageRoot);
          });

        await Promise.all(uploadPromises);
        updateDatasetInStore(currentDatasetId);
      } catch (error) {
        console.error(error);
      } finally {
        setTablesBackdropOpen(false);
      }
    },
    [currentDatasetId, storageRoot, updateDatasetInStore, uploadFile, setTablesBackdropOpen]
  );
  return (
    <>
      <input hidden id="new-zip-file-upload" type="file" onChange={handleFileUpload} accept=".zip" multiple />
      <label htmlFor="new-zip-file-upload">
        <Button variant="contained" component="span" startIcon={<FolderZip />}>
          Charger un ZIP
        </Button>
      </label>
    </>
  );
};

NewZipFileButton.propTypes = {
  storageRoot: PropTypes.string,
};
