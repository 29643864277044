import { put, takeEvery, call, select } from 'redux-saga/effects';
import { Api } from '../../../../services/config/Api';
import { DatasetsUtils } from '../../../../utils';
import { DATASET_ACTIONS_KEY, DATASET_PERMISSIONS_MAPPING } from '../../../commons/DatasetConstants';
import { RUNNER_ACTIONS_KEY } from '../../../commons/RunnerConstants';
import { WORKSPACE_ACTIONS_KEY } from '../../../commons/WorkspaceConstants';
import { dispatchSetApplicationErrorMessage } from '../../../dispatchers/app/ApplicationDispatcher';

function* getLastRunInfos(runner, organizationId, workspaceId) {
  if (runner.lastRun) {
    const {
      data: { state, id },
    } = yield call(Api.RunnerRuns.getRun, organizationId, workspaceId, runner.id, runner.lastRun.runnerRunId);
    return { id: runner.id, lastRunState: state, lastRunId: id };
  } else {
    return { id: runner.id, lastRunState: null, lastRunId: null };
  }
}
const getUserEmail = (state) => state.auth.userEmail;

const getDataset = (state, datasetId) => state.dataset.list.data.find((dataset) => dataset.id === datasetId);
export function* updateDataStoreAssociatedRunners(action) {
  try {
    const organizationId = action.organizationId;
    const workspaceId = action.workspaceId;
    const { data: runners } = yield call(Api.Runners.listRunners, organizationId, workspaceId, undefined, 10000);
    const validationRunner = runners.find((runner) => {
      return (
        runner.parametersValues.some((param) => {
          return param.parameterId === 'source_dataset_id' && param.value === action.datasetId;
        }) && runner.runTemplateId === 'datastore_checking'
      );
    });

    const pushRunner = runners.find((runner) => {
      return (
        runner.parametersValues.some((param) => {
          return param.parameterId === 'source_dataset_id' && param.value === action.datasetId;
        }) && runner.runTemplateId === 'push_datastore'
      );
    });
    let pushRunnerNewlyCreatedDatasetId;
    if (pushRunner !== undefined) {
      pushRunnerNewlyCreatedDatasetId = pushRunner.parametersValues.find(
        (param) => param.parameterId === 'last_confirmed_dataset_id'
      )?.value;
    }
    // runner state is currently not handled properly, need to fetch last run state instead

    const validationRunnerInfos = yield call(getLastRunInfos, validationRunner, organizationId, workspaceId);
    validationRunnerInfos.name = 'validation';
    const pushRunnerInfos = yield call(getLastRunInfos, pushRunner, organizationId, workspaceId);
    pushRunnerInfos.name = 'push';

    const dataset = yield select(getDataset, action.datasetId);
    const { associatedRunners: prevAssociatedRunners } = dataset;
    // updating only if no previous associated runner found
    // or if the state of the run changed in order not to trigger dataset object update
    let updateAssociatedRunnersInStore = false;
    for (const runnerInfos of [validationRunnerInfos, pushRunnerInfos]) {
      if (!prevAssociatedRunners || prevAssociatedRunners[runnerInfos.name]?.state !== runnerInfos.lastRunState) {
        updateAssociatedRunnersInStore = true;
        if (runnerInfos.lastRunState === 'Successful') {
          const {
            data: { tags },
          } = yield call(Api.Datasets.findDatasetById, organizationId, action.datasetId);
          yield put({
            type: DATASET_ACTIONS_KEY.UPDATE_DATASET,
            datasetId: action.datasetId,
            datasetData: { ...dataset, tags },
          });
        }
      }
    }
    if (pushRunnerNewlyCreatedDatasetId) {
      const isDatasetInStoreIndex = yield select(getDataset, pushRunnerNewlyCreatedDatasetId);
      if (isDatasetInStoreIndex === undefined && pushRunnerInfos.lastRunState === 'Successful') {
        // try-catch in case dataset has been manually deleted
        try {
          const { data: dataset } = yield call(
            Api.Datasets.findDatasetById,
            organizationId,
            pushRunnerNewlyCreatedDatasetId
          );
          const userEmail = yield select(getUserEmail);
          DatasetsUtils.patchDatasetWithCurrentUserPermissions(dataset, userEmail, DATASET_PERMISSIONS_MAPPING);
          console.log('we re supposed to add dataset to state here');
          yield put({
            type: DATASET_ACTIONS_KEY.ADD_DATASET,
            ...dataset,
          });
          yield put({
            type: WORKSPACE_ACTIONS_KEY.LINK_TO_DATASET,
            datasetId: dataset.id,
            workspaceId,
          });
        } catch {}
      }
    }
    if (updateAssociatedRunnersInStore) {
      yield put({
        type: DATASET_ACTIONS_KEY.UPDATE_DATASTORE_ASSOCIATED_RUNNERS,
        datasetId: action.datasetId,
        associatedRunnersDetails: {
          validation: {
            id: validationRunnerInfos.id,
            lastRunId: validationRunnerInfos.lastRunId,
            state: validationRunnerInfos.lastRunState,
          },
          push: { id: pushRunnerInfos.id, lastRunId: pushRunnerInfos.lastRunId, state: pushRunnerInfos.lastRunState },
        },
      });
    }
    // TODO : fetch dataset info + update redux
    // to see confirmed datastore in dataset manager without manual refresh
    // select dataset with SELECT action key
    // yield put({
    //   type: DATASET_ACTIONS_KEY.GET_ALL_DATASETS,
    //   organizationId,
    // });
  } catch (error) {
    console.error(error);
    yield put(
      dispatchSetApplicationErrorMessage(
        error,
        // t('commoncomponents.banner.runnerNotCreated', "Runner hasn't been created")
        "Le runner associé à ce dataset n'a pas été trouvé"
      )
    );
  }
}

export function* updateDataStoreAssociatedRunnersSaga() {
  yield takeEvery(
    RUNNER_ACTIONS_KEY.TRIGGER_SAGA_UPDATE_DATASTORE_ASSOCIATED_RUNNERS,
    updateDataStoreAssociatedRunners
  );
}
