import React, { useCallback, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { UploadFile } from '@mui/icons-material';
import { Button } from '@mui/material';
// import { gzipSync } from 'fflate';
import { useCurrentDatasetId } from '../../../../../../../../state/hooks/DatasetHooks';
import { ValidationContext, TablesBackdropOpenContext } from '../../../../../../DataStore';
import { useDataStoreFileDelete } from '../../../../../../DataStoreHooks';
import { useDataStoreFileUpload } from '../../UploadFileButtonsHooks';

export const NewFileButton = ({ storageRoot }) => {
  const currentDatasetId = useCurrentDatasetId();
  const { uploadFile, updateDatasetInStore } = useDataStoreFileUpload();
  const { validation, isArchiveAvailable, isReportAvailable } = useContext(ValidationContext);
  const { setTablesBackdropOpen } = useContext(TablesBackdropOpenContext);
  const deleteFile = useDataStoreFileDelete();
  const inputRef = useRef();

  const handleFileUpload = useCallback(
    async (event) => {
      const files = event.target.files;
      if (files.length === 0) return;
      setTablesBackdropOpen(true);
      const updatedValidation = { ...validation };
      updatedValidation.status = validation.status ? 'modified' : 'draft';
      const allPromises = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // const arrayBuffer = await file.arrayBuffer();
        // const compressedData = gzipSync(new Uint8Array(arrayBuffer));
        // const compressedFile = new File([compressedData], file.name);
        const uploadPromise = uploadFile(file, storageRoot).catch((error) => console.log(error));
        allPromises.push(uploadPromise);
        updatedValidation.tables = {
          ...updatedValidation.tables,
          [file.name]: {
            status: Object.keys(updatedValidation.tables).includes(file.name) ? 'modified' : 'draft',
            details: [],
          },
        };
      }
      const validationBlob = new Blob([JSON.stringify(updatedValidation)]);
      const validationFile = new File([validationBlob], '_validation.json');
      const validationUploadPromise = uploadFile(validationFile, storageRoot).catch((error) => console.log(error));
      allPromises.push(validationUploadPromise);

      if (isArchiveAvailable) {
        const datastoreArchiveDeletePromise = deleteFile(`${storageRoot}datastore.zip`).catch((error) =>
          console.error(error)
        );
        allPromises.push(datastoreArchiveDeletePromise);
      }
      if (isReportAvailable) {
        const validationReportDeletePromise = deleteFile(`${storageRoot}validation_report.txt`).catch((error) =>
          console.error(error)
        );
        allPromises.push(validationReportDeletePromise);
      }

      Promise.all(allPromises)
        .then(() => {
          updateDatasetInStore(currentDatasetId);
        })
        .finally(() => {
          setTablesBackdropOpen(false);
          inputRef.current.value = '';
        });
    },
    [
      currentDatasetId,
      storageRoot,
      updateDatasetInStore,
      validation,
      uploadFile,
      setTablesBackdropOpen,
      deleteFile,
      isArchiveAvailable,
      isReportAvailable,
    ]
  );
  return (
    <>
      <input
        ref={inputRef}
        hidden
        id="new-file-upload"
        type="file"
        onChange={handleFileUpload}
        accept=".csv,.CSV"
        multiple
      />
      <label htmlFor="new-file-upload">
        <Button variant="contained" component="span" startIcon={<UploadFile />}>
          Charger au format CSV
        </Button>
      </label>
    </>
  );
};

NewFileButton.propTypes = {
  storageRoot: PropTypes.string,
};
