import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle, Cancel, Help } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material';

const fileStatusIcons = {
  valid: <CheckCircle color="success" />,
  error: <Cancel color="error" />,
  draft: <Help color="default" />,
  modified: <Help color="yellowModified" />,
};

const customTheme = createTheme({
  palette: {
    yellowModified: {
      main: 'goldenrod',
      contrastText: '#000000',
    },
  },
});
export const FileStatusIcon = ({ status }) => {
  return <ThemeProvider theme={customTheme}>{fileStatusIcons[status] || <Help color="default" />}</ThemeProvider>;
};

FileStatusIcon.propTypes = {
  status: PropTypes.string,
};
