import React from 'react';
import PropTypes from 'prop-types';
import { PlayCircle } from '@mui/icons-material';
import { CircularProgress, IconButton, Box } from '@mui/material';
import { FadingTooltip } from '@cosmotech/ui';
import { useStartRunner } from '../../../../DataStoreHooks';

export const StartCheckingButton = ({ running, disabled }) => {
  const startRunner = useStartRunner();
  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <FadingTooltip title="Lancer la vérification du magasin de données.">
          <IconButton
            color="inherit"
            onClick={() => startRunner('validation')}
            disabled={running || disabled}
            sx={{ fontSize: '45px' }}
          >
            <PlayCircle fontSize="inherit" />
          </IconButton>
        </FadingTooltip>
        {running && (
          <CircularProgress
            size={51}
            sx={{
              color: 'white',
              position: 'absolute',
              top: 5,
              left: 5,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </>
  );
};

StartCheckingButton.propTypes = {
  running: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};
