import React, { useCallback } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDataStoreList } from './DataStoreItemsListHooks';

export function DataStoreItemsList() {
  const { dataStoreItems, currentDataset, selectDatasetAndRunner } = useDataStoreList();

  const handleDataStoreSelection = useCallback(
    (event) => {
      const slectedDatasetId = event.target.value;
      const matchedDataStore = dataStoreItems.find((dataStoreItem) => dataStoreItem.id === slectedDatasetId);
      selectDatasetAndRunner(matchedDataStore);
    },
    [selectDatasetAndRunner, dataStoreItems]
  );
  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="datastore-select-label">Sélectionnez un magasin de données</InputLabel>
        <Select
          labelId="datastore-select-label"
          id="datastore-select"
          value={currentDataset?.id || ''}
          label="Sélectionez un magasin de données"
          onChange={handleDataStoreSelection}
        >
          {dataStoreItems.map((dataStoreItem) => (
            <MenuItem value={dataStoreItem.id} key={dataStoreItem.id}>
              {dataStoreItem.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
